// Contextual backgrounds
// [converter] $parent hack
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent}:hover {
    background-color: darken($color, 10%) !important;
  }
}
