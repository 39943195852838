#site-header {
    background-color: $emory-black;
    border-bottom: 2px solid $emory-gold;
    a {
        color: $white;
    }
}

.c-header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.global-header {
    padding: 60px 0 30px;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
}

.c-header__nav ul li {
  animation: FadeIn 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.c-header__nav ul li {
    &:nth-child(1) { animation-delay: 0.25s }
    &:nth-child(2) { animation-delay: 0.5s }
    &:nth-child(3) { animation-delay: 0.75s }
    &:nth-child(4) { animation-delay: 1s }
}

#c-mobile-nav__trigger {
    display: none;
    cursor: pointer;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
    }
    .c-mobile-nav__text {
        @include tn-link;
        padding-right: 2.65rem;
    }
    .c-mobile-nav__menu svg {
        width: 1.5em;
        top: .3em;
        height: .625em;
    }
    .c-mobile-nav__closed {
        transform: rotate(-180deg);
        transition: all .5s ease;
        svg {
            transform: translateY(5px);
        }
    }
}

nav {
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style-type: none;
        }
    }
}

.c-header nav ul {
    @media (max-width: 768px) {
        display: none;
    }
    display: flex;
    flex-direction: column;
    text-align: right;
    @media (min-width: $screen-xs-max) {
        flex-direction: row;
        text-align: left;
    }
    li {
        margin-left: 1em;
        @include tn-link;
    }
}

//
//
//

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}
.navbar a {
    outline-offset: -2px;
}
@media (min-width:992px) {
    .header-skip-link {
    position: fixed!important;
    top: 140px;
    left: 1rem;
    z-index: 2;
}
.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
}
.navbar-collapse {
    padding-bottom: 1.125rem;
}
.navbar--collapsed {
    padding-bottom: 0;
}
.navbar--collapsed .navbar-brand img {
    width: 240px;
}
}.navbar-nav .nav-link {
    font-weight: 700;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}
.navbar-nav .nav-link.active::after {
    height: 4px;
    right: 0;
    bottom: -1px;
    left: 0;
}
.navbar-nav .dropdown-toggle::after {
    content: '';
    display: none;
}
@media (min-width:992px) {
    .navbar-nav .nav-item {
    margin: 0 .25rem;
}
.navbar-nav .nav-item::after {
    height: 4px;
    right: 0;
    bottom: -1px;
    left: 0;
}
.navbar-nav .nav-item:last-of-type .dropdown-menu {
    right: -1px;
    left: auto;
}
}.navbar-dark {
    background-color: #000;
    border-bottom: solid 1px #53565A;
}
.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-toggler {
    color: #fff;
}
.navbar-dark .navbar-toggler {
    border: 1px solid #53565A;
}
.navbar-brand {
    width: 100%;
    margin-right: 0;
}
.navbar-brand img {
    width: 360px;
    transition: width .3s, height .3s;
}
@media (min-width:768px) {
    .navbar-brand {
    max-width: 26rem;
}
}
.nav-item {
    position: relative;
}
@media (min-width:992px) {
    .nav-item::after {
    content: '';
    background-color: transparent;
    display: block;
    position: absolute;
    transition: background-color .15s;
}
.nav-item.show::after, .nav-item:hover::after {
    background-color: #b58500;
}
}.side-nav {
    border-bottom-color: #d0d0ce;
}
.side-nav .nav-link {
    border-top: solid 1px #d0d0ce;
    border-right: none;
}
.nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
    position: relative;
}
.nav-link.active::after {
    content: '';
    background-color: #0234a6;
    display: block;
    border: none;
    border-radius: 0;
    position: absolute;
}
.dropdown-menu {
    margin: 0;
    padding: 0;
}
@media (min-width:992px) {
    .dropdown-menu {
    width: 11rem;
    min-width: calc(100% + 2px);
    margin: -1px 0 0 -1px;
}
}.dropdown-item, .side-nav .nav-link {
    font-size: .93rem;
    line-height: 1.2;
    color: #101820;
    position: relative;
}
.dropdown-item::after, .side-nav .nav-link::after {
    content: '';
    background-color: transparent;
    display: block;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}
.dropdown-item:hover, .side-nav .nav-link:hover {
    color: #012169;
}
.dropdown-item:hover::after, .side-nav .nav-link:hover::after {
    background-color: #b58500;
}
.dropdown-item.active, .side-nav .nav-link.active {
    color: #012169;
    background-color: transparent;
}
.dropdown-item.active::after, .side-nav .nav-link.active::after {
    background-color: #012169;
}
.dropdown-item {
    white-space: normal;
    padding: .75rem 1rem;
}
.navbar-toggler {
    font-weight: 700;
    margin: .5rem 0;
}
.navbar-toggler:focus {
    outline: #b58500 solid 2px;
}
.dropdown-item:focus, .dropdown-item:hover, .list-group-item-action:focus, .list-group-item-action:hover, .side-nav .nav-link:focus, .side-nav .nav-link:hover {
    background-color: #f6f6f6;
}
@media (max-width:767px) {
    .navbar-toggler {
    width: 100%;
    margin: .5rem 0 1rem;
}
}


.navbar-dark {
    background-color: $emory-black;
    background-color: #000;
    border-bottom: solid 1px #53565A;
    .nav-link {
        color: $white;
    }

}

.navbar {

    .navbar-collapse.d-lg-flex {
        @media (max-width: 992px) {
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-align: center;
            align-items: center;
            align-self: flex-end!important;
            &.collapse:not(.show) {
                display: none !important;
            }
        }
        @media (min-width: 992px) {
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
            padding-bottom: 1.125rem;
        }
    }
    .container-fluid:before, .container-fluid:after {
        content: '';
        display: none;
    }
    .nav > li > a:hover, .nav > li > a:focus {
        background-color: transparent;
    }
    .navbar-brand {
        height: auto;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        img {
            width: 360px;
            transition: width .3s,height .3s;
        }
        .img-fluid {
            max-width: 100%;
            height: auto;
        }
    }
}

@media (min-width: 992px) {
  .navbar--collapsed {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .navbar--collapsed .navbar-brand img {
    width: 240px;
  }
}

@media (max-width: 768px) {
    .navbar {
        margin-bottom: 0;
    }
    .content-wrap {
        padding-top: 24px;
    }
}

//
// Subnav - Account, Cart, Promo, etc.
//

.tn-subnav-component {
    .tn-logout-link, .tn-login-link .tn-link, .tn-account-info-link {
        @include tn-link;
    }
}

.tn-cart-link {
    .tn-link {
        @include tn-link;
    }
}

.tn-subnav-component .tn-cart-link.tn-active .tn-timer {
    // No hover or interaction change for timer
    color: $text-color !important;
}

#tn-subnav-promo-code {
    background: 0 0;
    transition: box-shadow .5s,border-color .25s ease-in-out;
    border: 1px solid $emory-black;
    outline: 0 !important;
    color: $text-color;

    padding-left: 0.5em;
    height: 34px;
}

#tn-apply-promo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tn-subnav-component .tn-promo-box button.tn-subnav-promo-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-decoration: none;
    font-size: 0;
    padding-right: 2.5em;
    height: 34px;
    width: 36px;
    background-color: $brand-primary;
    position: relative;
    border: 1px solid $emory-black;
    border-width: 1px 1px 1px 0;
    &:before {
        content: "\E080";
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size: 16px;
        font-family: Glyphicons Halflings;
        font-style: normal;
        color: $white;
    }
}
