
.max-width {
    @media (max-width: $screen-xs-max) {
        padding: 0 5%;
    }
    @media (min-width: $screen-xs-max) {
        width: 80%;
    }
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

}

.page-wrap {
    background-color: $page-background;
}
.content-wrap {
    background: $light-bg;
    padding-bottom: 50px;
    min-height: 90vh;
    @media (min-width: $screen-xs-max) {
        padding: 1% 3%;
    }
}
// inset style used throughout

@mixin inset() {
    background: rgba($white, 0.8);
    border: 1px solid  rgba(0,0,0,0.09);
    padding: 20px;
    border-radius: 4px;
}

.tn-events-filter-component,
.tn-listing-component,
.tn-calendar-component,
.tn-cart-component,
.tn-listing-legend-container {
    @include inset();
}
