$transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);

@mixin tn-link {
    font-weight: bold;
    outline: 0!important;
    transition: $transition;
    color: $emory-blue;
    &:hover, &:active {
        color: darekn($emory-blue, 5%);
        text-decoration: underline;
    }
    a {
        transition: $transition;
        color: $emory-blue;
        &:hover, &:active {
            color: darekn($emory-blue, 5%);
            text-decoration: underline;
        }
    }
}

//
// Make page titles Gold
//
.page-wrap {
    .tn-heading {
        padding: 0 0 20px 0;
        color: $emory-black;
    }
}

//
// remove padding on second main container
//
main.tn-events-listing-page {
    padding-top: 0;
}


//
// Make preview button absolute
//
.tn-admin-preview-banner {
    position: fixed;
    bottom: 0;
    z-index: 9999;
}


//
// Base Css 
// 

a, hr {
    color: #012169;
}
.breadcrumb a, .wysiwyg a {
    text-decoration: underline;
}
* {
    border-radius: 0!important;
}
:focus {
    outline: #b58500 solid 2px;
    box-shadow: none;
}
body {
    font-family: 'Open Sans', sans-serif;
    color: #101820!important;
    background-color: #101820;
}
.bg-primary {
    background-color: #012169!important;
}
.bg-black {
    background-color: #101820;
}
.btn, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}

hr {
    background-color: #012169;
    height: 3px;
}
a {
    transition: color .15s;
}
a:hover {
    color: #012c8c;
}
a.text-secondary, a.text-secondary:focus, a.text-secondary:hover {
    color: #ffbc03!important;
}
.lead {
    font-weight: 400;
}
.btn {
    cursor: pointer;
}

.container-fluid {
    max-width: 1800px;
}
@media (min-width:992px) {
    main.page-wrap {
    padding-top: 126px;
    position: relative;
}
}

