#tn-account-login-forgot-login-link, a.tn-additional-events__continue-shopping-link {
    @include tn-link;
}

.tn-cart-buttons__secondary-action,
#tn-update-form .btn-default,
.tn-account-interests-page .btn-default,
.tn-account-logout-page .tn-btn-link,
.tn-account-update-password-page .btn-default {
    @include tn-link;
    background-color: transparent;
    border: 0;
    padding-left: 0;
    padding-right: 0;
}

