//
// Cart
//

.tn-cart-details-page {
    .tn-cart-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (min-width: $screen-md-max) {
            margin-top: 60px;
        }
    }

    .tn-empty-cart-message {
        margin-bottom: 0px;
    }

    .tn-cart-buttons__primary-action {
        margin-bottom: 0;
    }
    .tn-cart-buttons__secondary-action {
        @include tn-link;
    }
    .tn-cart-item-detail__list {
        padding: $padding-base-vertical 0;
    }
    .tn-cart-item:first-of-type {
        border-top: 0;
    }
}


.tn-edit-billing-link, .tn-edit-delivery-link {
    @include tn-link;
}

.tn-cart-item-summary__action-item--edit-package {
   @include tn-link;
}

// Remove bootstrap width overrides
.tn-patron-billing-information, .tn-patron-shipping-information, .tn-order-notes-component {
    .col-sm-6 {
        max-width: unset;
        width: unset;
        float: none;
    }
}

.tn-order-notes-component .col-sm-6 {
    flex: 0 0 100%;
    max-width: 800px;
}

a.tn-edit-delivery-link, a.tn-edit-billing-link {
    display: block;
    margin-top: 12px;
}

