#site-footer {
    background-color: $emory-blue;
    border-top: 2px solid $emory-gold;
    padding: 30px 0 60px;
    a {
        color: $white;
    }
}

footer {
    overflow: hidden;
    border-top: solid 5px #b58500;
}
footer a {
    color: #b58500;
}
footer a:hover {
    color: #ffca36;
}
@media (min-width:576px) {
    footer>.container-fluid {
    position: relative;
}
footer>.container-fluid::after {
    content: '';
    background-size: contain;
    display: block;
    height: 270px;
    width: 270px;
    opacity: .1;
    position: absolute;
    right: 1.5rem;
    bottom: -2rem;
    z-index: 1;
}
}footer>.container-fluid .row {
    position: relative;
    z-index: 2;
}
footer .footer__social {
    font-size: 1.75rem;
}
footer .footer__social a {
    color: #fff;
    outline: transparent solid 1px;
    transition: outline-color .15s;
}
footer .footer__social a:hover {
    outline-color: #b58500;
}
footer .footer__social a:focus {
    outline: #b58500 solid 2px;
}
.footer__name {
    font-size: 1.125rem;
    font-weight: 700;
}
.footer__contact li::after, .footer__copyright li::after {
    content: '\25AA';
    color: #b1b3b3;
    margin-left: .5em;
}
.footer__contact li:last-child::after, .footer__copyright li:last-child::after {
    content: ''}
.footer__copyright {
    font-size: .75rem;
}

.footer__contact, .footer__social {
    white-space: nowrap;
    flex-grow: 0;
}

.footer--star {
    display: none;
}

@media (min-width: 576px) {
    .footer--star {
        background-size: contain;
        display: block;
        height: 270px;
        width: 270px;
        opacity: .1;
        position: absolute;
        right: 1.5rem;
        bottom: -2rem;
        z-index: 1;
    }
}
